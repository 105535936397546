<template>
  <b-modal
      v-model="ownControl"
      scrollable
      hide-footer
      title-class="h3 text-white font-weight-bolder"
      title="LOAN TRACKING PAY DAY"
      size="lg"
      @hidden="closeModal"
  >
    <div>
      <b-card
          no-body

          class="mb-0"
      >

        <b-table

            small

            :fields="fields"
            :items="search"
            class="font-small-3 text-center"
        >

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"/>
              <strong>Loading ...</strong>
            </div>
          </template>

          <template v-slot:cell(user)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.username  }}
            </p>

          </template>

          <template v-slot:cell(before_pay_day)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.before_pay_day | myGlobal }}
            </p>

          </template>

          <template v-slot:cell(after_pay_day)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.after_pay_day | myGlobal }}
            </p>

          </template>
          <template v-slot:cell(updated_at)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.created_at | myGlobalDay }}
            </p>

          </template>

        </b-table>
      </b-card>
    </div>
  </b-modal>
</template>
<script>
import dataFields from '@/views/commons/components/loans/modals/fields.data.js'
import LoansService from "@/views/commons/components/loans/services/loans.service"


export default {
  props: {

    index: null,

  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      fields: dataFields,
      items: {},
    }
  },
  created() {
    this.ownControl = true
  },
  methods: {

    closeModal() {
      this.$emit('close')
    },

    async search() {
      try {
        const params = {

          id_loan: this.index,
        }
        const data = await LoansService.getTrackingLoanPayDay(params)

        this.items = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>

</style>
