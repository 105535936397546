<template>
  <div :class="isTabsBorder">
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalData"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      @reload="$refs.refLoansList.refresh()"
    >
      <template #buttons>
        <b-button
          v-if="moduleId !== 4"
          variant="success"
          class="ml-1"
          :disabled="!selectedUsers.length"
          @click="exportExcel"
        >
          <feather-icon icon="MessageCircleIcon" class="mr-50" />EXPORT EXCEL
        </b-button>
      </template>
      <template #table>
        <b-table
          ref="refLoansList"
          class="position-relative"
          :items="search"
          :has-provider="true"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          small
          hover
          no-border-collapse
          sticky-header="65vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          no-provider-filtering
          thead-class="text-center"
          tbody-class="text-center"
        >
          <!-- Head: Check -->
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column: Selected -->
          <template #cell(selected)="data">
            <b-form-group>
              <b-form-checkbox v-model="selectedUsers" :value="data.item" />
            </b-form-group>
          </template>

          <template #cell(amount_loan)="data">
            <div>
              <span style="color: #4141af"
                >{{ data.item.currency == 1 ? "$ " : "S/ " }}
                {{ data.value | currency }}</span
              >
            </div>
          </template>

          <template #cell(balance)="data">
            <div>
              <span :class="data.value > 0 ? 'text-danger' : 'text-success'">
                {{ data.item.currency == 1 ? "$ " : "S/ " }}
                {{ data.value | currency }}</span
              >
            </div>
          </template>

          <template #cell(due_payment)="data">
            <div>
              <b
                :class="
                  data.item.status_loan == 4
                    ? 'text-dark'
                    : data.item.due_date > dateNow
                    ? 'text-success '
                    : 'text-danger'
                "
                >{{
                  data.item.due_id
                    ? data.item.currency == 1
                      ? "$ "
                      : "S/ "
                    : ""
                }}{{ data.item.due_payment | currency }}</b
              >
            </div>
          </template>

          <template #cell(due_date)="data">
            <div v-if="data.item.due_id">
              <span>{{ data.item.due_date | myGlobal }}</span>
              <b-icon
                v-if="isManagement"
                icon="pencil-square"
                variant="success cursor-pointer ml2"
                font-scale="1"
                @click="editDatePay(data.item)"
              />
              <feather-icon
                v-if="isManagement && data.item.is_tracking"
                icon="AlignJustifyIcon"
                variant="secondary cursor-pointer ml2"
                size="16"
                @click="openTrackingPayDay(data.item.id_loan)"
              />
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </template>

          <template #cell(payment_time)="data">
            <div>
              {{ data.item.payment_time }}
              {{
                data.item.payment_time > 1
                  ? "Dues"
                  : data.item.payment_time == 0
                  ? "-"
                  : "Due"
              }}
            </div>
          </template>

          <template #cell(due_id)="data">
            <div>
              {{
                data.item.due_id
                  ? changeOrdinal(data.item.due) + " Due"
                  : "Finished"
              }}
            </div>
          </template>

          <template #cell(created_at)="data">
            <div>{{ data.value | myGlobal }}</div>
          </template>

          <template #cell(process)="data">
            <div class="font-weight-bolder">{{ data.value }}</div>
          </template>

          <template #cell(status)="data">
            <b-badge
              class="fs100 w-100"
              :variant="
                data.item.status_loan == 4
                  ? 'dark'
                  : data.item.status_tracking == 2
                  ? 'danger'
                  : data.item.rol_tracking == 4 &&
                    data.item.status_tracking == 1
                  ? 'success'
                  : 'warning'
              "
              >{{ data.value }}</b-badge
            >
          </template>

          <template #cell(id_loan)="data">
            <b-button
              v-b-tooltip.hover
              size="sm"
              title="tracking"
              variant="dark button-little-size"
              @click="openTrackingLoan(data.item.id_loan)"
            >
              <b-icon icon="stoplights" />
            </b-button>
          </template>

          <template #cell(status_loan)="data">
            <b-button
              v-b-tooltip.hover
              size="sm"
              title="SEND EMAIL"
              class="button-little-size ml6"
              variant="gradient-primary"
              @click="sendEmailLoans(data.item)"
              v-if="isManagement"
            >
              <tabler-icon icon="MailIcon" />
            </b-button>

            <b-button
              v-b-tooltip.hover
              size="sm"
              title="INFO OF LOAN"
              class="button-little-size ml6"
              variant="gradient-info"
              @click="openModalLoanId(data.item.id_loan)"
            >
              <b-icon icon="info-circle-fill" />
            </b-button>

            <b-button
              v-if="(tab == 1 && data.item.status_loan == 3) || isExternalLoan"
              v-b-tooltip.hover
              size="sm"
              title="ADD NEW PAY"
              class="button-little-size ml6"
              variant="gradient-success"
              @click="openModalPay(data.item.id_loan, data.item.due_id)"
            >
              <b-icon icon="wallet-fill" />
            </b-button>
            <span
              v-if="
                (tab == 1 || isManagement) &&
                [3, 4].includes(data.item.status_loan)
              "
              style="position: relative"
            >
              <b-button
                v-b-tooltip.hover
                size="sm"
                title="REVISION PAYMENT"
                class="button-little-size ml6"
                variant="gradient-warning"
                @click="openModalRevision(data.item.id_loan)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="16"
                  :badge="
                    data.item.counter > 0
                      ? data.item.counter > 9
                        ? '9+'
                        : data.item.counter
                      : ''
                  "
                  badge-classes="badge-danger badge-glow"
                />
              </b-button>
            </span>
            <b-button
              v-if="
                (tab == 1 || isManagement) &&
                [3, 4].includes(data.item.status_loan)
              "
              v-b-tooltip.hover
              size="sm"
              title="INVOICE"
              class="button-little-size ml6"
              variant="gradient-secondary"
              @click="openModalInvoice(data.item.id_loan)"
            >
              <b-icon icon="file-earmark-spreadsheet" />
            </b-button>
            <b-button
              v-if="isManagement"
              variant="danger"
              @click="removeLoan(data.item.id_loan)"
              class="button-little-size ml6"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
          <template v-if="loans.length > 0" #custom-foot>
            <b-tr>
              <b-td> </b-td>
              <b-td class="text-center"> Subtotal </b-td>
              <b-td class="text-center">
                <b-badge variant="secondary" class="w-100"
                  >$ {{ subTotalAmountLoan | currency }}</b-badge
                >
                <br />
                <b-badge variant="secondary" class="w-100" v-if="isExternalLoan"
                  >S/ {{ subTotalAmountLoanSoles | currency }}</b-badge
                >
              </b-td>
              <b-td class="text-center">
                <b-badge variant="secondary" class="w-100"
                  >$ {{ subTotalBalanceLoan | currency }}</b-badge
                >
                <br />
                <b-badge variant="secondary" class="w-100" v-if="isExternalLoan"
                  >S/ {{ subTotalBalanceLoanSoles | currency }}</b-badge
                >
              </b-td>
              <b-td class="text-center">
                <b-badge variant="secondary" class="w-100"
                  >$ {{ subTotalDuePayment | currency }}</b-badge
                >
                <br />
                <b-badge variant="secondary" class="w-100" v-if="isExternalLoan"
                  >S/ {{ subTotalDuePaymentSoles | currency }}</b-badge
                >
              </b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr>
              <b-td> </b-td>
              <b-td class="text-center"> Total </b-td>
              <b-td class="text-center">
                <b-badge variant="secondary" class="w-100"
                  >$ {{ totalAmountLoan | currency }}</b-badge
                >
                <br />
                <b-badge variant="secondary" class="w-100" v-if="isExternalLoan"
                  >S/ {{ totalAmountLoanSoles | currency }}</b-badge
                >
              </b-td>
              <b-td class="text-center">
                <b-badge variant="secondary" class="w-100"
                  >$ {{ totalBalanceLoan | currency }}</b-badge
                >
                <br />
                <b-badge variant="secondary" class="w-100" v-if="isExternalLoan"
                  >S/ {{ totalBalanceLoanSoles | currency }}</b-badge
                >
              </b-td>
              <b-td class="text-center">
                <b-badge variant="secondary" class="w-100"
                  >$ {{ totalDuePayment | currency }}</b-badge
                >
                <br />
                <b-badge variant="secondary" class="w-100" v-if="isExternalLoan"
                  >S/ {{ totalDuePaymentSoles | currency }}</b-badge
                >
              </b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <ModalTrackingLoan
      v-if="modalsInfo.tracking"
      :info="modalsInfo"
      @hide="closeModals"
    />
    <ModalNewPay
      v-if="modalsInfo.newPay"
      :info="modalsInfo"
      @hide="closeModals"
    />
    <ModalRevisionPayment
      v-if="modalsInfo.revisionPay"
      :info="modalsInfo"
      @hide="closeModals"
    />
    <ModalInvoice
      v-if="modalsInfo.invoice"
      :info="modalsInfo"
      @hide="closeModals"
    />
    <ModalChangeDayPay
      :loanSelected="loanSelected"
      v-if="showModalDate"
      @close="closeEditDatePay"
      @refresh="resetSearch"
    >
    </ModalChangeDayPay>

    <ModalTrackingChangeDayPay
      v-if="trackingPayDayOn"
      @close="closeTrackingPayDay"
      :index="idLoanTracking"
    ></ModalTrackingChangeDayPay>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import ModalTrackingLoan from "./modals/ModalTrackingLoan.vue";
import ModalNewPay from "./modals/ModalNewPay.vue";
import ModalRevisionPayment from "./modals/ModalRevisionPayment.vue";
import ModalInvoice from "./modals/ModalInvoice.vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import loansService from "@/views/commons/components/loans/services/loans.service";
import ModalChangeDayPay from "@/views/commons/components/loans/modals/ModalChangeDayPay.vue";
import ModalTrackingChangeDayPay from "@/views/commons/components/loans/modals/ModalTrackingChangeDayPay.vue";
import ModalShowExternalLoan from "@/views/commons/components/loans/modals/ModalShowExternalLoan.vue";

export default {
  name: "LoansTable",
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    AppCollapse,
    AppCollapseItem,
    ModalTrackingLoan,
    ModalNewPay,
    ModalRevisionPayment,
    ModalInvoice,
    FilterSlot,
    ModalChangeDayPay,
    ModalTrackingChangeDayPay,
    ModalShowExternalLoan,
  },
  props: {
    tab: {
      type: Number,
      default: null,
    },
    status: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      modalsInfo: {
        tracking: false,
        newPay: false,
        revisionPay: false,
        invoice: false,
        idLoan: null,
        dueId: null,
      },
      loans: [],
      advance: false,
      user_session: null,
      startdate: "",
      enddate: "",
      start_page: "",
      next_page: "",
      last_page: "",
      total_data: "",
      nameUser: "",
      showModalDate: false,
      modalTracking: false,
      modalTrackingPayDay: false,
      idLoan: null,
      idDue: null,
      status_search: "",
      isNoRefresh: false,
      formatDate: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      loanSelected: [],
      dateNow: moment().format("YYYY-MM-DD"),
      sortBy: "created_at",
      sortDesc: true,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      arrayColumns: [
        {
          key: "selected",
          label: "selected",
          tdClass: "font-weight-bolder py-1",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "username",
          label: "User",
          tdClass: "font-weight-bolder py-1",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "amount_loan",
          label: "Amount Loan",
          tdClass: "font-weight-bolder",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "balance",
          label: "Balance Loan",
          tdClass: "font-weight-bolder",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "due_payment",
          label: "Monthly Payment",
          tdClass: "font-weight-bolder",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "due_date",
          label: "Pay day",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "payment_time",
          label: "Dues",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "due_id",
          label: "Current Due",
          tdClass: "text-center",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "created_at",
          label: "Creation Date",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "process",
          label: "Status Location",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "loan_category",
          label: "Category",
          thClass: "font-weight-bolder py-1",
          visible: this.$route.meta.type_tab == 2,
        },
        {
          key: "id_loan",
          label: "Tracking",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
        {
          key: "status_loan",
          label: "Actions",
          thClass: "font-weight-bolder py-1",
          visible: true,
        },
      ],
      searchInput: "",
      orderby: "",
      order: "",
      startPage: null,
      endPage: null,
      totalData: null,
      perPage: 10,
      nextPage: "",
      currentPage: 1,
      toPage: null,
      isBusy: false,
      perPageOptions: [10, 25, 50, 100],
      isClientsTab: false,
      fromToObject: {
        from: null,
        to: null,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search User...",
        model: "",
      },
      filters: [
        {
          type: "datepicker",
          cols: 6,
          margin: true,
          showLabel: true,
          label: "From",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
        },
        {
          type: "datepicker",
          cols: 6,
          margin: true,
          showLabel: true,
          label: "To",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
        },
        {
          type: "select",
          cols: 12,
          margin: true,
          showLabel: true,
          label: "Status",
          placeholder: "",
          class: "font-small-3",
          model: null,
          options: [
            { value: null, label: "All" },
            { value: 1, label: "Pending" },
            { value: 2, label: "Disapproved" },
            { value: 3, label: "Approved" },
            { value: 4, label: "Finished" },
          ],
          reduce: "value",
          selectText: "label",
        },
        {
          type: "select",
          cols: 12,
          margin: true,
          showLabel: true,
          label: "Category",
          placeholder: "",
          class: "font-small-3",
          model: null,
          options: [],
          reduce: "id",
          selectText: "description",
          visible: this.$route.meta.type_tab == 2,
        },
      ],
      programs: [],
      trackingPayDayOn: null,
      showModalLoanPays: null,
      idLoanTracking: null,
      selectAll: false,
    };
  },
  created() {
    this.getAllLoanCategories();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
      loading: "commissions-store/loading",
      researchLoans: "loans-store/researchLoans",
      modalRequest: "loans-store/modalRequest",
    }),
    isManagement() {
      return this.moduleId === 16;
    },
    isTabsBorder() {
      return this.tab === 2 || this.tab === 3
        ? "border-top-info border-3 box-shadow-3 rounded-bottom"
        : "";
    },
    isExternalLoan() {
      return this.$route.meta.type_tab == 2;
    },
    visibleFields() {
      return this.arrayColumns.filter((item) => item.visible);
    },
    selectedUsers: {
      get() {
        return this.$store.state["loans-store"].selectedUsers;
      },
      set(value) {
        this.$store.commit("loans-store/SET_SELECTED_USERS", value);
      },
    },
    subTotalAmountLoan() {
      let loans = this.loans.filter(({ currency }) => currency == 1);
      return loans.reduce((acc, item) => {
        return (Number(acc) + Number(item.amount_loan)).toFixed(2);
      }, 0);
    },
    subTotalBalanceLoan() {
      let loans = this.loans.filter(({ currency }) => currency == 1);
      return loans.reduce((acc, item) => {
        return (Number(acc) + Number(item.balance)).toFixed(2);
      }, 0);
    },
    subTotalDuePayment() {
      let loans = this.loans.filter(({ currency }) => currency == 1);
      return loans.reduce((acc, item) => {
        return (Number(acc) + Number(item.due_payment)).toFixed(2);
      }, 0);
    },
    subTotalAmountLoanSoles() {
      let loans = this.loans.filter(({ currency }) => currency == 2);
      return loans.reduce((acc, item) => {
        if (item.currency == 2)
          return (Number(acc) + Number(item.amount_loan)).toFixed(2);
        else return 0;
      }, 0);
    },
    subTotalBalanceLoanSoles() {
      let loans = this.loans.filter(({ currency }) => currency == 2);
      return loans.reduce((acc, item) => {
        if (item.currency == 2)
          return (Number(acc) + Number(item.balance)).toFixed(2);
        else return 0;
      }, 0);
    },
    subTotalDuePaymentSoles() {
      let loans = this.loans.filter(({ currency }) => currency == 2);
      return loans.reduce((acc, item) => {
        if (item.currency == 2)
          return (Number(acc) + Number(item.due_payment)).toFixed(2);
        else return 0;
      }, 0);
    },
    totalAmountLoan() {
      return this.loans[0] ? this.loans[0].cc_amount_loan : 0;
    },
    totalBalanceLoan() {
      return this.loans[0] ? this.loans[0].cc_balance : 0;
    },
    totalDuePayment() {
      return this.loans[0] ? this.loans[0].cc_due_payment : 0;
    },
    totalAmountLoanSoles() {
      return this.loans[0] ? this.loans[0].cc_amount_loan_soles : 0;
    },
    totalBalanceLoanSoles() {
      return this.loans[0] ? this.loans[0].cc_balance_soles : 0;
    },
    totalDuePaymentSoles() {
      return this.loans[0] ? this.loans[0].cc_due_payment_soles : 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "app/SET_LOADING",
    }),
    async sendEmailLoans(loan) {
      this.addPreloader();
      try {
        await loansService.sendEmailLoans({
          id_loan: loan.id_loan,
          type: this.$route.meta.type_tab ? this.$route.meta.type_tab : 1,
        });
        this.show;
        this.removePreloader();
        this.showToast(
          "success",
          "top-right",
          "Congratulations!",
          "CheckIcon",
          "Invoice sent successfully"
        );
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    async exportExcel() {
      const response = await this.showConfirmSwal();
      if (response.value) {
        try {
          const response = await loansService.exportExcel({
            selectedUsers: this.selectedUsers,
          });
          await this.forceFileDownload(response, "Loans.xlsx");
          this.$refs.refLoansList.refresh();
          this.showSuccessSwal();
        } catch (error) {
          this.showErrorSwal();
        }
      }
    },

    selectedAll() {
      if (this.selectAll) {
        this.selectedUsers = [...this.loans];
      } else {
        this.selectedUsers = [];
      }
    },
    // Searching Table
    async search(ctx) {
      try {
        const params = {
          page: ctx.currentPage,
          type: this.tab,
          nameUser: this.filterPrincipal.model,
          user_id: this.currentUser.user_id,
          role_id:
            this.currentUser.role_id == 17 ? 2 : this.currentUser.role_id,
          id_module: this.moduleId,
          startdate: this.filters[0].model,
          enddate: this.filters[1].model,
          status: this.status,
          status_search: this.filters[2].model,
          perPage: this.paginate.perPage,
          type_tab: this.$route.meta.type_tab ? this.$route.meta.type_tab : 1,
          category: this.filters[3].model,
        };
        const response = await loansService.getLoans(params);
        this.loans = response.data;
        this.startPage = response.from;
        this.paginate.currentPage = response.current_page;
        this.paginate.perPage = response.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.last_page;
        this.totalData = response.total;
        this.toPage = response.to;
        return this.loans;
      } catch (error) {
        this.showErrorSwal();
        this.isBusy = false;
        return [];
      }
    },
    resetSearch() {
      this.searchInput = "";
      this.$refs.refLoansList.refresh();
    },
    async getAllLoanCategories() {
      let response = await loansService.getAllLoanCategories();
      this.filters[3].options = response;
    },
    // Methods to Edit
    openTrackingPayDay(id) {
      this.idLoanTracking = id;
      this.trackingPayDayOn = true;
    },
    closeTrackingPayDay(id) {
      this.trackingPayDayOn = false;
    },

    async removeLoan(loan_id) {
      const willDelete = await this.showConfirmSwal(
        "Are you sure?",
        "Once deleted, you will not be able to recover this data!"
      );
      if (willDelete.value) {
        try {
          loansService.removeLoan({
            id_loan: loan_id,
            user_id: this.currentUser.user_id,
          });
          this.$refs.refLoansList.refresh();
          this.showSuccessSwal();
        } catch (error) {
          this.showErrorSwal();
        }
      }
    },
    editDatePay(loan) {
      this.loanSelected = JSON.parse(JSON.stringify(loan));
      this.showModalDate = true;
    },
    closeEditDatePay() {
      this.showModalDate = false;
    },
    changeOrdinal(number) {
      const s = ["th", "st", "nd", "rd"];
      const v = number % 100;
      return number + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    openModalPays(id) {
      this.idLoan = id;
      this.showModalLoanPays = true;
    },

    // OPEN MODALS
    openModalLoanId(id) {
      this.modalRequest.idLoan = id;
      this.modalRequest.external = this.$route.meta.type_tab
        ? this.$route.meta.type_tab
        : 1;
      this.modalRequest.show = true;
    },
    openModalPay(id, due) {
      this.modalsInfo.idLoan = id;
      this.modalsInfo.idDue = due;
      this.modalsInfo.newPay = true;
      this.addPreloader();
    },
    openModalInvoice(id) {
      this.addPreloader();
      this.modalsInfo.idLoan = id;
      this.modalsInfo.invoice = true;
    },
    openTrackingLoan(id) {
      this.addPreloader();
      this.modalsInfo.idLoan = id;
      this.modalsInfo.tracking = true;
    },
    openModalRevision(id) {
      this.addPreloader();
      this.modalsInfo.idLoan = id;
      this.modalsInfo.revisionPay = true;
    },
    // CLOSE MODALS
    closeModals(status) {
      // Close Modals
      this.modalsInfo.newPay = false;
      this.modalsInfo.tracking = false;
      this.modalsInfo.revisionPay = false;
      this.modalsInfo.invoice = false;

      // Clean Info
      this.modalsInfo.idLoan = null;
      this.modalsInfo.idDue = null;

      // Research
      if (status) {
        this.$store.commit("loans-store/ADD_ONE_RESEARCH");
        this.$store.dispatch("loans-store/loadCounterTab");
      }
    },
  },
  watch: {
    researchLoans(newValue) {
      if (newValue) {
        this.resetSearch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
td.div {
  width: 100% !important;
}
@media (max-width: 960px) {
  .column-table {
    display: flex;
    flex-direction: column;
  }
}
.ml2 {
  margin-left: 2px;
}
.ml6 {
  margin-left: 7px;
}
.fs100 {
  font-size: 100%;
}
.span-counter {
  position: absolute;
  left: 15px;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  bottom: 7px;
  color: white;
  font-weight: 900;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
