export default [
  {
    key: 'user',
    sortable: false,
    label: 'User',
  },
  {
    key: 'before_pay_day',
    sortable: false,
    label: 'Before Pay Day',
  },
  {
    key: 'after_pay_day',
    sortable: false,
    label: 'After Pay Day',
  },
  {
    key: 'updated_at',
    sortable: false,
    label: 'Updated At',
  },


]
