<template>
  <b-modal
    v-model="ownControl"
    title="Pay Day"
    size="md"
    modal-class="top-modal"
    hide-footer
    @hidden="closeModal"
  >
    <validation-observer ref="form">
      <b-row class="text-center">
        <b-col>
          <validation-provider name="DATE">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="title_lable"
                  >DATE
                </b-input-group-text>
              </template>

              <b-form-datepicker
                id="date"
                :min="minDate"
                v-model="loanSelected.due_date"
                placeholder="Select Date"
                :date-format-options="formatDate"
              />
            </b-input-group>
          </validation-provider>
        </b-col>
        <b-col
          ><b-button
            variant="success"
            class="mr-1 reset-radius"
            @click="updateDate"
          >
            UPDATE
          </b-button></b-col
        >
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import LoansService from "@/views/commons/components/loans/services/loans.service";

export default {
  name: "ModalChangeDayPay",
  props: {
    loanSelected: null,
  },
  data() {
    return {
      formatDate: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      spinnerOn: false,
      minDate: "",
    };
  },
  created() {
    this.ownControl = true;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    // eslint-disable-next-line consistent-return
    async updateDate() {
      try {
        this.spinnerOn = true;
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const result = await this.$refs.form.validate();
          if (result) {
            const params = {
              id_loan: this.loanSelected.id_loan,
              due_date: this.loanSelected.due_date,
              user_id: this.currentUser.user_id,
            };

            const data = await LoansService.updateDateLoan(params);
            if (data.status === 200) {
              this.$emit("refresh");
              this.showSuccessSwal();
              this.closeModal();
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
</style>
